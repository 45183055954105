import {useState} from 'react';

const Product=({key,product,setImageView})=>{
  const supplier=product.company=='rosella'?false:true;
  const cost=product.price?product.price:0;
  const shipping=Math.round(cost*0.05);
  const sellingPrice=(product.price+shipping)+((product.price+shipping)*(product.benefit/100));

  //Toptan
  const wholeSellingPrice=sellingPrice+(product.benefitWhole?(sellingPrice*(product.benefitWhole/100)):0)
  const onePayment=wholeSellingPrice+(wholeSellingPrice*0.02);
  const threePayment=wholeSellingPrice+(wholeSellingPrice*0.08);
  const twentyFivePayment=wholeSellingPrice+(wholeSellingPrice*0.15);
  const sixPayment=wholeSellingPrice+(wholeSellingPrice*0.18);

  const setupPrice=Math.round(sellingPrice*0.05);

  //Fabrika Mağ. Sat.
  const factoryPrice=sellingPrice+setupPrice+(product.benefitFactoryStore?(sellingPrice*(product.benefitFactoryStore/100)):0);
  const minFactoryPrice=factoryPrice-(factoryPrice*(0.15));
  const factoryEmployee=minFactoryPrice-(minFactoryPrice*(0.10));

  //Mağaza Sat.
  const storeOnePayment=wholeSellingPrice+(sellingPrice*0.02);
  const storeThreePayment=wholeSellingPrice+(sellingPrice*0.08);
  const storeTwentyFivePayment=wholeSellingPrice+(sellingPrice*0.15);
  const storeSixPayment=wholeSellingPrice+(sellingPrice*0.18);
  const storePrice=sellingPrice+setupPrice+(product.benefitStore?(sellingPrice*(product.benefitStore/100)):0)
  const minStorePrice=storePrice-(storePrice*(0.15));
  const storeEmployee=minStorePrice-(minStorePrice*(0.10));

  const [more,setMore]=useState(false);
  const checkBasket=window.basket.find((o)=>(o.barcode==product.barcode));
  const [inBasket,setInBasket]=useState(checkBasket);


  return(
    <div style={{width:'100%',background:window.color.five,borderRadius:20,marginBottom:5,paddingBottom:10}}>
      <div style={{width:'95%',padding:10,justifyContent:'start',flexDirection:'row',justifyContent:'space-between'}}>
        <div style={{}}>
          <div style={{fontWeight:'600',textTransform:'Uppercase'}}>
            {product.name==''?'İsimsiz':product.name}
          </div>
          <div style={{fontSize:11}}>
            {product.company+(product.group?'/'+(product.group+(product.group2?'/':'')+product.group2):'')}
          </div>
          <div style={{fontSize:11,fontWeight:'500',display:storePrice>0?'flex':'none'}}>
            {Math.round(storePrice).toLocaleString()} TL
          </div>
        </div>
        <div style={{flexDirection:'row'}}>
        {window.auth=='admin'?
        <label
        onClick={()=>{window.setAdd({item:product,action:'edit'})}}
        style={{fontSize:10,width:70}}>
          <img
          style={{width:20}}
          src={require('../Assets/EditIcon.png')}/>
          Düzenle
        </label>
        :null}
        {window.auth=='none'?
        <label
        onClick={()=>{window.setAdd({item:product,action:'stock'})}}
        style={{fontSize:10,width:70}}>
          <img
          style={{width:20}}
          src={require('../Assets/StockIcon.png')}/>
          Stok Ekle
        </label>
        :null}
        <label
        onClick={()=>{
          product.setInBasket=setInBasket;
          window.setAdd({item:product,action:'addorder',title:product.name});
        }}
        style={{fontSize:10,width:70}}>
          <img
          style={{width:20}}
          src={require('../Assets/ShopIcon.png')}/>
          {inBasket?'Sepette':'Sepete Ekle'}
        </label>
        </div>
      </div>
      <div style={{flexDirection:'row',width:'100%',justifyContent:'start',marginTop:10,marginBottom:10}}>
        {product.images?.map((img,index)=>{
          return(
            <img
            key={index}
            onClick={()=>{setImageView(img.adress)}}
            style={{minWidth:'100%',maxWidth:'100%',aspectRatio:16/9,objectFit:'cover',borderRadius:5}}
            src={img.adress}/>
          );
        })}
      </div>

      <label
      onClick={()=>{setMore(!more)}}
      style={{fontSize:11,padding:10,width:'20%'}}>
        <img
        style={{width:25,height:25,transform:more?'rotate(270deg)':'rotate(90deg)'}}
        src={require('../Assets/ArrowIcon.png')}/>
      </label>
      {more?
      <>
      <div style={{flexDirection:'row',width:'100%',justifyContent:'space-evenly',paddingTop:20,paddingBottom:20}}>
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:11}}>
            <div style={{fontSize:12,fontWeight:'900'}}>Boyutlar</div>
            <div style={{flexDirection:'row',justifyContent:'space-evenly'}}>
              <div style={{padding:1.5}}>
                Yükseklik
                <div style={{fontWeight:'700',fontSize:11}}>{product.height?product.height:0}cm</div>
              </div>
              <div style={{padding:1.5}}>
                Genişlik
                <div style={{fontWeight:'700',fontSize:11}}>{product.width?product.width:0}cm</div>
              </div>
              <div style={{padding:1.5}}>
                Derinlik
                <div style={{fontWeight:'700',fontSize:11}}>{product.depth?product.depth:0}cm</div>
              </div>
            </div>
            <div style={{flexDirection:'row',justifyContent:'space-evenly',width:'100%'}}>
              <div style={{padding:3}}>
                Yük Kap.
                <div style={{fontWeight:'700',fontSize:11}}>{product.capacity?product.capacity:0}kg</div>
              </div>
              <div style={{padding:3}}>
                Profil Gen.
                <div style={{fontWeight:'700',fontSize:11}}>{product.profileWidth?product.profileWidth:0}cm</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:12,fontWeight:'900'}}>Tür</div>
          <div style={{display:'block',fontSize:11,maxHeight:100,fontWeight:'500'}}>
            <div style={{padding:3}}>
              {!product.color[0]?
                <div style={{alignSelf:'start'}}>Bulunmamakta.</div>
              :null}
              {product.color.map((i,index)=>{return(
                <div key={index} style={{}}>{i}</div>
              )})}
            </div>
          </div>
        </div>
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:12,fontWeight:'900'}}>Alt Tür</div>
          <div style={{display:'block',fontSize:11,maxHeight:100,fontWeight:'500'}}>
            <div style={{padding:3}}>
              {!product.color2[0]?
                <div style={{alignSelf:'start'}}>Bulunmamakta.</div>
              :null}
              {product.color2.map((i,index)=>{return(
                <div key={index} style={{}}>{i}</div>
              )})}
            </div>
          </div>
        </div>
      </div>
      <div style={{flexDirection:'row',width:'100%',justifyContent:'space-evenly',paddingTop:20}}>
        {window.auth=='admin'?
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:11}}>
            <div style={{fontSize:12,fontWeight:'900',paddingBottom:3}}>Fabrika</div>
            Maliyet
            <div style={{fontWeight:'700',fontSize:11}}>{cost.toLocaleString()} TL</div>
            Nakliyat
            <div style={{fontWeight:'700',fontSize:11}}>{shipping} TL</div>
            Kàr
            <div style={{fontWeight:'700',fontSize:11}}>%{product.benefit?product.benefit:0}</div>
            Peşin
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(sellingPrice).toLocaleString()} TL</div>
          </div>
        </div>
        :null}

        {window.auth=='sales'||window.auth=='admin'?
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:11}}>
            <div style={{fontSize:12,fontWeight:'900',paddingBottom:3}}>Toptan</div>
            Peşin
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(wholeSellingPrice).toLocaleString()} TL</div>
            K.Kartı Tek çekim
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(onePayment).toLocaleString()} TL</div>
            K.Kartı 3 Taksit
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(threePayment).toLocaleString()} TL</div>
            %25 Peşin Kalan 6 Ay Vade
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(twentyFivePayment).toLocaleString()} TL</div>
            6 Ay Vade
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(sixPayment).toLocaleString()} TL</div>
          </div>
        </div>
        :null}

        {window.auth=='factorystore'||window.auth=='admin'?
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:11}}>
            <div style={{fontSize:12,fontWeight:'900',paddingBottom:3}}>Fab. Mağaza</div>
            Satış
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(factoryPrice).toLocaleString()} TL</div>
            Minumum
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(minFactoryPrice).toLocaleString()} TL</div>
            Personel
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(factoryEmployee).toLocaleString()} TL</div>
          </div>
        </div>
        :null}

        {window.auth=='store'||window.auth=='admin'||window.auth=='manager'?
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:11}}>
            <div style={{fontSize:12,fontWeight:'900',paddingBottom:3}}>Mağaza</div>
            {window.auth=='admin'||window.auth=='manager'?
            <>
            Alış
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(sellingPrice).toLocaleString()} TL</div>
            K.Kartı Tek çekim
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(storeOnePayment).toLocaleString()} TL</div>
            K.Kartı 3 Taksit
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(storeThreePayment).toLocaleString()} TL</div>
            %25 Peşin Kalan 6 Ay Vade
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(storeTwentyFivePayment).toLocaleString()} TL</div>
            6 Ay Vade
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(storeSixPayment).toLocaleString()} TL</div>
            </>
            :null}
            Satış
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(storePrice).toLocaleString()} TL</div>
            Minumum
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(minStorePrice).toLocaleString()} TL</div>
            Personel
            <div style={{fontWeight:'700',fontSize:11}}>{Math.round(storeEmployee).toLocaleString()} TL</div>
          </div>
        </div>
        :null}

      </div>
      {product.features?
      <div style={{padding:20}}>
        <div style={{fontSize:12,fontWeight:'900'}}>
          Ürün Ek Bilgisi
        </div>
        <div style={{fontSize:13}}>
          {product.features}
        </div>
      </div>
      :null}
      </>
      :null}
    </div>
  );
}
export default Product;
