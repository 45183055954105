import {lazy,Suspense} from 'react';

const SmallAdd=()=>{

  const AddOrder=lazy(()=>import('../Order/AddOrder'));

  const Component=window.smallAdd.action=='addorder'?AddOrder:null;

  return(
    <div style={{zIndex:2,position:'absolute',width:'100%',height:'100%'}}>
      <div style={{position:'absolute',width:'100%',height:'100%',background:'black',opacity:0.7}}/>
      <div style={{position:'absolute',width:'95%',height:'70%',maxWidth:600,background:window.color.five,borderRadius:10}}>
        <div style={{flexDirection:'row',height:40,width:'100%',justifyContent:'space-between'}}>
          <button onClick={()=>{window.setSmallAdd(false)}} style={{height:30,width:30}}>
            <img
            style={{height:20,width:20,transform:'rotate(45deg)'}}
            src={require('../Assets/PlusIcon.png')}/>
          </button>
          <div style={{fontSize:13,fontWeight:'bold'}}>
            {window.smallAdd.title}
          </div>
          <button onClick={()=>{window.smallAdd.update()}} style={{height:30,width:30}}>
            <img
            style={{height:20,width:20}}
            src={require('../Assets/CheckIcon.png')}/>
          </button>
        </div>
        <div style={{display:'block',width:'100%',height:'100%'}}>
          <Suspense>
            <Component/>
          </Suspense>
        </div>
      </div>
    </div>

  );
}


export default SmallAdd;
