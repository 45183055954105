import {useState,useEffect,useRef,useMemo} from 'react';
import {collection,getDocs,query,where,or,orderBy,limit,startAfter} from 'firebase/firestore';
import {db} from '../firebase';
import Stock from './Stock';
import AddandEdit from '../Components/AddandEdit';
import Select from '../Components/Select';

const Stocks=()=>{

  const [add,setAdd]=useState(false);
  const [data,setData]=useState(window.doc.stocks??[]);
  const [place,setPlace]=useState('');
  const [statue,setStatue]=useState('');
  const [search,setSearch]=useState('');

  const [company,setCompany]=useState('');
  const [group,setGroup]=useState('');
  const [group2,setGroup2]=useState('');

  const more=useRef(false);

  window.setAdd=setAdd;
  window.add=add;
  window.getStocks=getStocks;

  useEffect(()=>{
    if(!data[0]){
      getStocks();
    }
    else{
      more.current=true
      more.lastDoc=window.stocksLastDoc;
    }
    return()=>{
      window.stocksLastDoc=more.lastDoc;
    }
  },[])

  function getStocks(){
    const lim=3;
    const conditions=[orderBy('updated'),limit(lim)];
    const stList=[
    {name:'place',state:place},
    {name:'statue',state:statue},
    {name:'company',state:company},
    {name:'group',state:group},
    {name:'group2',state:group2}
  ];
    stList.map((st)=>{
      if(st.state){conditions.push(where(st.name,'==',st.state))}
    })
    if(more.current=='more'){
      conditions.push(startAfter(more.lastDoc))
    }

    const q=query(collection(db,'stocks'),...conditions);
    const qS=query(collection(db,'stocks'),or(where('barcode','==',search),where('name','==',search)));
    const qT=search?qS:q;
    window.functions.getDocuments('stocks',data,setData,qT,more,lim);
  }


  const StockMemo=useMemo(()=>{
    if(data[0]){
      return data.map((i)=>{
        return <Stock stock={i}/>
      })
    }
  },[data])


  return(
    <div style={{width:'100%',height:'100%',justifyContent:'start'}}>
      <div
      onScroll={(e)=>{window.functions.onScroll(e,more,getStocks)}}
      style={{width:'100%',maxWidth:700,display:'block',justifyContent:'start',paddingBottom:200}}>
        <Search
        place={place}
        setPlace={setPlace}
        statue={statue}
        setStatue={setStatue}
        search={search}
        setSearch={setSearch}

        company={company}
        setCompany={setCompany}
        group={group}
        setGroup={setGroup}
        group2={group2}
        setGroup2={setGroup2}/>
        {StockMemo}
      </div>
      {add?
      <AddandEdit/>
      :null}
    </div>
  );
}
export default Stocks;





const Search=({place,setPlace,statue,setStatue,search,setSearch,company,setCompany,group,setGroup,group2,setGroup2})=>{

  const categories=window.doc.infos.categories??null;
  const [showList,setShowList]=useState(false);

  function downloadDocs(){
    const d=new Date();
    const month=(d.getMonth()+1).toString().padStart(2,'0');
    const date=d.getDate().toString().padStart(2,'0')+'-';
    const hours=d.getHours().toString().padStart(2,'0');
    const minutes=d.getMinutes().toString().padStart(2,'0');
    getDocs(collection(db,'stocks')).then((doc)=>{
      const d=[];
      doc.forEach((item)=>{
        d.push(item.data())
      })
      const a = document.createElement('a');
      a.href = `data:text/plain,${JSON.stringify(d)}`;
      a.download = 'Stok '+month+date+hours+minutes+'.txt'
      document.body.appendChild(a);
      a.click();
    })
  }

  return(
    <div style={{width:'100%',paddingBottom:10,paddingTop:10,background:window.color.five,borderRadius:20,marginBottom:5}}>
      <div
      onClick={()=>{setShowList(!showList)}}
      style={{padding:5,flexDirection:'row',width:'90%',justifyContent:'space-between'}}>
        <label style={{}}>
          <div style={{fontSize:20,letterSpacing:2,fontWeight:'bold'}}>
            ROSELLA
          </div>
          <div style={{fontSize:11,letterSpacing:2,marginTop:-5}}>
            Home Consept
          </div>
        </label>
        <img
        onClick={()=>{}}
        style={{width:30,height:30,transform:showList?'rotate(270deg)':'rotate(90deg)'}}
        src={require('../Assets/ArrowIcon.png')}/>
      </div>

      <div style={{width:'100%',display:showList?'flex':'none',paddingTop:20,paddingBottom:10}}>

        <div style={{width:'70%',flexDirection:'row',padding:20,justifyContent:'space-evenly'}}>
          <Select
          setState={setPlace}
          list={['Fabrika','Konya','Antalya']}
          title={'Yer'}
          all={true}
          clean={[setSearch]}/>

          <Select
          setState={setStatue}
          list={['Bekleme','Nakliyat','Kurulum','Satıldı']}
          title={'Durum'}
          all={true}
          clean={[setSearch]}/>

          <button
          style={{fontSize:12,padding:5}}
          onClick={()=>{window.getStocks()}}>
            Listele
          </button>
        </div>

        <div style={{width:'90%',flexDirection:'row',padding:20,justifyContent:'space-evenly'}}>

          <Select
          setState={setCompany}
          list={Object.keys(categories)}
          title={'Firma'}
          all={true}
          selected={company}
          titleSelected={company}
          clean={[setSearch,setGroup,setGroup2]}/>

          <Select
          setState={setGroup}
          list={categories[company]}
          listObject={'name'}
          disabled={company}
          title={'Sınıf'}
          all={true}
          selected={group}
          titleSelected={group}
          clean={[setSearch,setGroup2]}/>

          <Select
          setState={setGroup2}
          list={categories[company]?.find((o)=>(o.name==group))?.kind}
          disabled={group}
          title={'Alt Sınıf'}
          all={true}
          selected={group2}
          titleSelected={group2}/>

          <button
          style={{fontSize:12,padding:5}}
          onClick={()=>{window.getStocks()}}>
            Listele
          </button>
        </div>
        <div style={{padding:20,width:'75%',flexDirection:'row'}}>
          <input
          style={{width:'50%',height:20,borderRadius:5}}
          type='text'
          placeHolder='Stok Kodu ya da Ürün İsmi'
          value={search}
          onChange={(e)=>{setSearch(e.target.value.toLowerCase())}}/>
          <button
          style={{fontSize:12,marginLeft:'7%',padding:5,paddingLeft:10,paddingRight:10}}
          onClick={()=>{
            setPlace('');
            setStatue('');
            setCompany('');
            setGroup('');
            setGroup2('');
            window.getStocks();
          }}>
            Ara
          </button>
        </div>
        {window.dev?
        <label onClick={()=>{downloadDocs()}} style={{alignSelf:'end',paddingRight:20,fontSize:12}}>Verileri Indir</label>
        :null}
      </div>

    </div>
  );
}
