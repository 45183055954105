import {useState,useEffect} from 'react';

const Alert=()=>{
  const [y,setY]=useState(0);

  useEffect(()=>{
    animation('up');
    setTimeout(()=>{animation('down')},3000);
  },[])

  function animation(to){
    let id=setInterval(frame,10);
    function frame(){
      setY(prev=>{
        if(to=='up'){
          if(prev<40){
            return prev+4;
          }
          else{
            clearInterval(id);
            return 40;
          }
        }
        else{
          if(prev>-40){
            return prev-4;
          }
          else{
            clearInterval(id);
            window.setAlert(false);
            return -40;
          }
        }
      })
    }
  }

  return(
    <div style={{zIndex:2,position:'fixed',width:'100%',height:40,bottom:y,background:window.color.six,borderRadius:10}}>
      {window.alert.message}
    </div>
  );
}
export default Alert;
