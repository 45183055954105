import {useState,useEffect} from 'react';
import Sign from './Sign';
import Navigation from './Navigation';
import Alert from './Components/Alert';
import Loading from './Components/Loading';
import Question from './Components/Question';
import AddandEdit from './Components/AddandEdit';
import SmallAdd from './Components/SmallAdd';
import Products from './Product/Products';
import Stocks from './Stock/Stocks';
import Orders from './Order/Orders';
import Store from './Store/Store';

const App=()=>{
  const [signed,setSigned]=useState(false);
  const [alert,setAlert]=useState(false);
  const [loading,setLoading]=useState(false);
  const [question,setQuestion]=useState(false);
  const [navigate,setNavigate]=useState('Products');
  const [add,setAdd]=useState(false);
  const [smallAdd,setSmallAdd]=useState(false);
  const [basket,setBasket]=useState([]);

  window.signed=signed;
  window.setSigned=setSigned;
  window.alert=alert;
  window.setAlert=setAlert;
  window.loading=loading;
  window.setLoading=setLoading;
  window.navigate=navigate;
  window.setNavigate=setNavigate;
  window.question=question;
  window.setQuestion=setQuestion;
  window.add=add;
  window.setAdd=setAdd;
  window.smallAdd=smallAdd;
  window.setSmallAdd=setSmallAdd;
  window.basket=basket;
  window.setBasket=setBasket;

  return(
    <div style={{width:'100%',height:'100%',textTransform:'capitalize'}}>
      {signed=='Signed'?
      navigate=='Products'?<Products/>:
      navigate=='Stocks'?<Stocks/>:
      navigate=='Orders'?<Orders/>:
      navigate=='Store'?<Store/>:null:
      <Sign/>}
      {signed=='Signed'?<Navigation/>:null}
      {window.alert?
      <Alert/>
      :null}
      {window.loading?
      <Loading/>
      :null}
      {window.question?
      <Question/>
      :null}
      {window.add?
      <AddandEdit/>
      :null}
      {window.smallAdd?
      <SmallAdd/>
      :null}
    </div>
  );
}

export default App;
