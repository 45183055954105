const Question=()=>{

  function approved(){
    window.question.thisFunction();
    window.setQuestion(false);
  }

  return (
    <div style={{zIndex:3,position:'fixed',width:'100%',height:'100%'}}>
      <div style={{zIndex:-1,position:'fixed',width:'100%',height:'100%',background:'black',opacity:0.5}}/>
      <div style={{width:'100%',height:'30%',background:window.color.six,borderRadius:30}}>
        <div style={{paddingBottom:'4vh'}}>
          {window.question.message}
        </div>
        <div style={{flexDirection:'row',width:150,justifyContent:'space-between'}}>
          <button onClick={()=>{window.setQuestion(false)}} style={{height:30,width:30,borderRadius:10,background:window.color.five}}>
            <img
            style={{height:20,width:20,transform:'rotate(45deg)'}}
            src={require('../Assets/PlusIcon.png')}/>
          </button>
          <button onClick={()=>{approved()}} style={{height:30,width:30,borderRadius:10,background:window.color.five}}>
            <img
            style={{height:20,width:20}}
            src={require('../Assets/CheckIcon.png')}/>
          </button>
        </div>
      </div>

    </div>
  );
}
export default Question;
