import {useState} from 'react';

const Navigation=()=>{

  const clr=window.color.five;
  const clr2=window.color.four2;

  return(
    <div style={{width:'100%',minHeight:40,background:window.color.five}}>
      <div style={{width:'100%',height:'100%',flexDirection:'row'}}>
        <label
        onClick={()=>{window.setNavigate('Products')}}
        style={{width:'100%',height:'100%',background:window.navigate=='Products'?clr2:clr}}>
          <img
          style={{width:20,height:20}}
          src={require('./Assets/HomeIcon.png')}/>
        </label>
        {window.auth=='none'?
        <label
        disabled={true}
        onClick={()=>{window.setNavigate('Stocks')}}
        style={{width:'100%',height:'100%',background:window.navigate=='Stocks'?clr2:clr}}>
          <img
          style={{width:20,height:20}}
          src={require('./Assets/StockIcon.png')}/>
        </label>
        :null}
        <label
        onClick={()=>{window.setNavigate('Orders')}}
        style={{width:'100%',height:'100%',background:window.navigate=='Orders'?clr2:clr}}>
          <img
          style={{width:20,height:20}}
          src={require('./Assets/ShopIcon.png')}/>
          <div style={{position:'absolute'}}>
            <div style={{marginLeft:33,fontSize:12,fontWeight:'500'}}>
              {window.basket.length>0?window.basket.length:null}
            </div>
          </div>
        </label>
        <label
        disabled={true}
        onClick={()=>{window.setNavigate('Store')}}
        style={{width:'100%',height:'100%',background:window.navigate=='Store'?clr2:clr}}>
          <img
          style={{width:20,height:20}}
          src={require('./Assets/StoreIcon.png')}/>
        </label>
      </div>
    </div>
  );
}

export default Navigation;
