const Stock=({stock})=>{

  return(
    <div style={{width:'100%',background:window.color.five,borderRadius:20,marginBottom:5,paddingBottom:10}}>
      <div style={{width:'95%',padding:10,justifyContent:'start',flexDirection:'row',justifyContent:'space-between'}}>
        <div style={{fontSize:12}}>
          <div style={{fontWeight:'600',textTransform:'Uppercase'}}>
            {stock.name==''?'İsimsiz':stock.name}
          </div>
          <div style={{fontSize:10}}>
            {stock.company+(stock.group?'/'+(stock.group+(stock.group2?'/':'')+stock.group2):'')}
          </div>
          <div style={{fontSize:10}}>
            {(stock.color?(stock.color+(stock.color2?'/':'')+stock.color2):'')}
          </div>
        </div>
        {window.auth=='admin'?
        <button onClick={()=>{window.setAdd({item:stock,action:'editstock'})}} style={{background:0,color:'black',fontSize:10}}>
          <img
          style={{width:20}}
          src={require('../Assets/EditIcon.png')}/>
          Düzenle
        </button>
        :null}
      </div>
      <div style={{width:'100%'}}>
        <div style={{width:'100%',flexDirection:'row',justifyContent:'space-between',fontSize:12,paddingTop:10,paddingBottom:10}}>
          <div style={{flex:1}}>
          Barkod
          <div style={{fontWeight:'700'}}>{stock.stockID}</div>
          </div>
          <div style={{flex:1}}>
            Yer
            <div style={{fontWeight:'700'}}>{stock.place}</div>
          </div>
          <div style={{flex:1}}>
            Durum
            <div style={{fontWeight:'700'}}>{stock.statue}</div>
          </div>
        </div>
        <div style={{width:'70%',flexDirection:'row',justifyContent:'space-between',fontSize:12,paddingTop:10,paddingBottom:10}}>
          <div style={{flex:1}}>
            Eklendi
            <div style={{fontWeight:'700'}}>{window.functions.timeSince(stock.added)}</div>
          </div>
          <div style={{flex:1}}>
            Güncellendi
            <div style={{fontWeight:'700'}}>{window.functions.timeSince(stock.updated)}</div>
          </div>
        </div>
        <div style={{width:'100%',flexDirection:'row',justifyContent:'space-between',fontSize:12,paddingTop:10,paddingBottom:10}}>
          {stock.addition?
          <div style={{flex:1}}>
            Ek Bilgi
            <div style={{fontWeight:'700'}}>{stock.addition}</div>
          </div>
          :null}
        </div>
      </div>
    </div>
  );
}
export default Stock;
