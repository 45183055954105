import {lazy,Suspense} from 'react';

const AddandEdit=()=>{

  const AddProduct = lazy(()=>import('../Product/AddProduct'));
  const AddStock = lazy(()=>import('../Product/AddStock'));
  const EditStock = lazy(()=>import('../Stock/EditStock'));
  const AddCategory = lazy(()=>import('../Product/AddCategory'));
  const AddOrder = lazy(()=>import('../Order/AddOrder'));
  const EditOrder = lazy(()=>import('../Store/EditOrder'));

  const Component=
  window.add.action=='edit'?AddProduct:
  window.add.action=='stock'?AddStock:
  window.add.action=='editstock'?EditStock:
  window.add.action=='addorder'?AddOrder:
  window.add.action=='editorder'?EditOrder:
  window.add.action=='category'?AddCategory:null;

  return(
    <div style={{zIndex:2,position:'absolute',top:0,width:'100%',background:window.color.five,minHeight:'100%',justifyContent:'flex-start'}}>
      <div style={{flexDirection:'row',height:40,width:'97%',justifyContent:'space-between'}}>
        <button onClick={()=>{window.setAdd(false)}} style={{height:30,width:30}}>
          <img
          style={{height:20,width:20,transform:'rotate(45deg)'}}
          src={require('../Assets/PlusIcon.png')}/>
        </button>
        <div style={{fontSize:13,fontWeight:'bold'}}>
          {window.add.title}
        </div>
        <button onClick={()=>{window.add.update()}} style={{height:30,width:30}}>
          <img
          style={{height:20,width:20}}
          src={require('../Assets/CheckIcon.png')}/>
        </button>
      </div>
      <div style={{display:'block',width:'100%',height:window.innerHeight-40}}>
        <Suspense>
          <Component/>
        </Suspense>
      </div>
    </div>
  );
}


export default AddandEdit;
