
const Select=({setState,list,listObject,disabled,selected,titleSelected,title,all,clean})=>{
  return(
    <select
    style={{width:100,padding:5,margin:3}}
    disabled={disabled==''?true:false}
    onChange={(e)=>{
      setState(e.target.value);
      clean?.map((setO)=>{
        setO('');
      })
    }}>
      {title?
      <option value='' disabled selected={titleSelected?false:true}>{title}</option>
      :null}
      {all?
      <option value=''>Tüm</option>
      :null}
      {list?.map((b)=>{
        const o=listObject?b[listObject]:b;
        return(
          <option value={o} selected={selected?.toString()==o}>{o}</option>
        );
      })}
    </select>
  );
}

export default Select;
